import React from "react"

import Main from "../sections/main"

import Layout from "../components/layout"

export default function App() {
  return (
    <Layout>
      <Main />
    </Layout>
  )
}
