import React from "react"

import playStoreLogo from "../../../static/images/app/playstore.png"
import appStoreLogo from "../../../static/images/app/appstore.png"


export default function App() {
  return (
    <div className="main__app" id="app">
      <h2>Get the app</h2>
      <p>
        Stay connected and get notified wherever you are with the mobile application available on Google Play and the App Store.
      </p>
      <div>
        <a className="main__app__playstore" href="https://play.google.com/store/apps/details?id=io.magel.sottsass">
          <img src={playStoreLogo} alt="Download on Google Play" />
        </a>
        <a className="menu__app__appstore" href="https://apps.apple.com/fr/app/magel-io/id1544605166">
          <img src={appStoreLogo} alt="Download on the App Store" />
        </a>
      </div>
    </div>
  )
}
