import React from "react"

import villageLogo from "../../../static/images/village.png"
import awsLogo from "../../../static/images/aws.png"
import cloudLogo from "../../../static/images/cloud.png"
import epitaLogo from "../../../static/images/epita.png"

export default function Partners() {
  return (
    <div className="main__partners">
      <h2>Our partners</h2>
      <div>
        <img src={villageLogo} alt="logo Village" />
        <img src={awsLogo} alt="logo AWS" />
        <img src={cloudLogo} alt="logo Cloud" />
        <img src={epitaLogo} alt="logo Epita" />
      </div>
    </div>
  )
}
