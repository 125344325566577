import React from "react"

import plan1X1 from "../../../static/images/schema/plan1_x1.png"

import plan2X1 from "../../../static/images/schema/plan2_x1.png"
import line2X1 from "../../../static/images/schema/line2_x1.png"

import plan3X1 from "../../../static/images/schema/plan3_x1.png"
import line3X1 from "../../../static/images/schema/line3_x1.png"

import plan4X1 from "../../../static/images/schema/plan4_x1.png"
import line4X1 from "../../../static/images/schema/line4_x1.png"

import plan5X1 from "../../../static/images/schema/plan5_x1.png"
import line5X1 from "../../../static/images/schema/line5_x1.png"

import plan6X1 from "../../../static/images/schema/plan6_x1.png"

import centerX1 from "../../../static/images/schema/center_x1.png"

export default function Info() {
  return (
    <header className="main__info">
      <h1 className="main__info__title">
        Connecting international buyers and sellers data{" "}
        <span>in real time</span>
      </h1>
      <p>
        We bring speed, safety, ease & fluidity to end-to-end trades by
        connecting counterparties with each other and transforming what used to
        be painful data communication into a competitive asset!
      </p>
      <div className="main__info__schema__parent">
        <div className="main__info__schema">
          <figure>
            <img
              className="main__info__schema__plan"
              src={plan1X1}
              alt="Magel.io plan 1"
            />
            <figcaption>Strong authentication</figcaption>
          </figure>
          <figure>
            <img
              className="main__info__schema__plan"
              src={plan2X1}
              alt="Magel.io plan 2"
            />
            <img
              className="main__info__schema__lines"
              src={line2X1}
              alt="Magel.io line 2"
            />
            <figcaption>Live data management</figcaption>
          </figure>
          <figure>
            <img
              className="main__info__schema__plan"
              src={plan3X1}
              alt="Magel.io plan 3"
            />
            <img
              className="main__info__schema__lines"
              src={line3X1}
              alt="Magel.io line 3"
            />
            <figcaption>Orders control</figcaption>
          </figure>
          <figure>
            <img
              className="main__info__schema__plan"
              src={plan4X1}
              alt="Magel.io plan 4"
            />
            <img
              className="main__info__schema__lines"
              src={line4X1}
              alt="Magel.io line 4"
            />
            <figcaption>Real time</figcaption>
          </figure>
          <figure>
            <img
              className="main__info__schema__plan"
              src={plan5X1}
              alt="Magel.io plan 5"
            />
            <img
              className="main__info__schema__lines"
              src={line5X1}
              alt="Magel.io line 5"
            />
            <figcaption>APIs</figcaption>
          </figure>
          <figure>
            <img
              className="main__info__schema__plan"
              src={plan6X1}
              alt="Magel.io plan 6"
            />
            <figcaption>Mobile</figcaption>
          </figure>
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0"
            y="0"
            viewBox="0 0 1046.7 687.2"
            style={{ whiteSpace: "break-spaces" }}
          >
            <line
              className="main__info__schema__outlines"
              x1="753.4"
              y1="203.1"
              x2="824.9"
              y2="244.6"
            />
            <path
              className="main__info__schema__outlines"
              d="M1014.2,356.5c20,20.6,16.3,56.3-10.6,71.3L908,481.2"
            />
            <path
              className="main__info__schema__outlines"
              d="M715.1,588.9l-100.8,56.3c-39.3,22-86.4,23.6-126.7,5.3"
            />
            <line
              className="main__info__schema__outlines"
              x1="301.2"
              y1="534.9"
              x2="203.7"
              y2="473.5"
            />
            <path
              className="main__info__schema__outlines"
              d="M134.2,305.8c5.4-5.3,11.7-9.9,18.8-13.8l88-48"
            />
          </svg>
          <img
            className="main__info__schema__center"
            src={centerX1}
            alt="Magel.io center"
          />
        </div>
      </div>
    </header>
  )
}
