import React from "react"

import Info from "./info"
import Jobs from "./jobs"
import Partners from "./partners"
import App from './app'

export default function Main() {
  return (
    <main className="main">
      <Info />
      <section>
        <Jobs />
        <Partners />
        <App />
      </section>
    </main>
  )
}
