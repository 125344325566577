import React from "react"

export default function Jobs() {
  return (
    <div id="jobs" className="main__jobs">
      <h2>Job offers</h2>
      <ul>
        <li>
          <article className="main__jobs__single">
            <header>
              <h3>Full Stack Engineer Intership</h3>
            </header>
            <div>
              <p>
                Founded in 2020, Magel.io aims to gather and transfer reliable
                data to all actors of an international trade in real-time.
              </p>
              <p>
                We’re looking for ambitious, product minded folks to join our
                engineering team and help us build the future of international
                trading!
              </p>
              <h4>Tech and how we work</h4>
              <p>
                The backend is mainly built with Python (Django) and the
                frontend in React. They interact through a GraphQL API (Apollo).
              </p>
              <p>
                The data is stored in a PostgreSQL database and we use Kafka to
                process data in real-time.
              </p>
              <p>
                Our continuous integration and delivery are handled by GitLab
                CI/CD.
              </p>
              <p>
                We mainly deploy our solution using Docker in AWS and the
                infrastructure is entirely described in Terraform.
              </p>
              <p>
                We communicate over Slack internally and we do our meetings via
                Google Meet.
              </p>
              <p>
                We like learning new technos and having passionate discussions
                about new frameworks, but we favour stability versus over
                engineering and always try to use thebest tools for the job.
              </p>
              <h4>Your profile</h4>
              <p>
                You want to participate in a challenging adventure and make a
                huge impact in a company that you’ve helped build. Magel.io is
                just starting but our ambition is to grow fast and our
                engineering team is the key to success.
              </p>
              <p>
                You’re passionate about building well crafted apps and solving
                big problems. But you also understand that tech should always
                serve your customers’ best interests
              </p>
              <p>
                You like taking responsibility and ownership of your projects.
                We don’t have time for micromanagement and you’ll be required to
                deliver end to end solutions in our platform.
              </p>
              <p>
                You are currently a BAC +4/5 student specializing in engineering
                and want to start your career in a startup environment where
                you’ll be able to learn (and fail) fast
              </p>
              <h4>Details</h4>
              <p>Contract: internship M1, break or end of studies</p>
              <p>Duration: 6 months</p>
              <p>Start: January 2021</p>
              <p>
                Location: Le Village by CA, 55 rue la Boétie 75008 Paris, remote
              </p>
              <h4>Apply</h4>
              <p>
                Please send your CV and cover letter to ​contact@magel.io​. Tell
                us why you feel you would be a good fit for us!
              </p>
            </div>
          </article>
        </li>
      </ul>
    </div>
  )
}
